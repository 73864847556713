var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("z-page", [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("el-row", { attrs: { span: 24 } }, [
          _c("div", { staticClass: "container info-card" }, [
            _c("div", { staticClass: "menu-list" }, [
              _c("div", { staticClass: "menu-title" }, [_vm._v("出库作业")]),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/stock-out/box",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("包裹装箱"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/stock-out/history",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("出库记录"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          hidden: _vm.data.wait_outbound_count == 0,
                          value: _vm.data.wait_outbound_count,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: "/warehouse/stock-out/packages",
                            },
                          },
                          [
                            _c("div", { staticClass: "menu-item" }, [
                              _vm._v(" 待出库包裹 "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/stock-out/tracking",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("单号回填"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "menu-list" }, [
              _c("div", { staticClass: "menu-title" }, [_vm._v("入库作业")]),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/inbound/input",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("入库录入"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/inbound/search",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("入库查询"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          hidden: _vm.data.wait_outbound_count == 0,
                          value: _vm.data.wait_exception_count,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: "/warehouse/inbound/exception",
                            },
                          },
                          [
                            _c("div", { staticClass: "menu-item" }, [
                              _vm._v(" 异常件 "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          hidden: _vm.data.wait_merge_package_count == 0,
                          value: _vm.data.wait_merge_package_count,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: "/warehouse/package-merge",
                            },
                          },
                          [
                            _c("div", { staticClass: "menu-item" }, [
                              _vm._v(" 合包申请 "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "menu-list" }, [
              _c("div", { staticClass: "menu-title" }, [_vm._v("上/下架")]),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/shelf/in-shelf",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("包裹上架"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/shelf/out-shelf",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("包裹下架"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/shelf/search",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("货架查询"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/shelf/find-package",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("找包裹"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "menu-list" }, [
              _c("div", { staticClass: "menu-title" }, [_vm._v("截单/退件")]),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          hidden: _vm.data.wait_cancel_count == 0,
                          value: _vm.data.wait_cancel_count,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: "/warehouse/return/intercept",
                            },
                          },
                          [
                            _c("div", { staticClass: "menu-item" }, [
                              _vm._v("截单"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: {
                          hidden: _vm.data.wait_return_count == 0,
                          value: _vm.data.wait_return_count,
                          max: 99,
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: "/warehouse/return/return-package",
                            },
                          },
                          [
                            _c("div", { staticClass: "menu-item" }, [
                              _vm._v("退件"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "menu-list" }, [
              _c("div", { staticClass: "menu-title" }, [_vm._v("工具")]),
              _c("div", { staticClass: "menu-content" }, [
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/tools/order-print-time",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("面单打印时间"),
                        ]),
                      ]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/tools/address-parse",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("地址解析"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "menu-block" },
                  [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          underline: false,
                          href: "/warehouse/tools/replace-label",
                        },
                      },
                      [
                        _c("div", { staticClass: "menu-item" }, [
                          _vm._v("换 单"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }