
import { Component } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'

@Component({
	components: {}
})
export default class extends Emitter {
	private data = {
		wait_cancel_count: 0,
		wait_exception_count: 0,
		wait_outbound_count: 0,
		wait_return_count: 0,
		wait_merge_package_count: 0
	}

	created() {
		this.getUnreadCount()
	}

	async getUnreadCount() {
		const { data } = await this.$axios.get('/v1/jobline/warehouse/statistic')
		if (data) this.data = data
	}
}
